import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import FileUploader from "@/components/FileUploader.vue";
import DocumentValidation from "@/components/DocumentValidation.vue";
import { store } from "./store";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-green/theme.css";
import HomePage from "@/components/HomePage.vue";
import ToastService from "primevue/toastservice";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css"; // Replace with your chosen theme

import "./custom-theme.css";

const routes = [
  { path: "/", component: HomePage, name: "" },
  {
    path: "/validation",
    component: FileUploader,
    name: "validation"
  },
  {
    path: "/analysis",
    component: DocumentValidation,
    name: "analysis"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const app = createApp(App);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.config.globalProperties.$store = store;
app.mount("#app");
