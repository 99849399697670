<template>
  <Menubar :model="items" />
  <div class="container">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@fortawesome/fontawesome-free/css/all.css";
import Menubar from "primevue/menubar";
import { MenuItem } from "primevue/menuitem";

export default defineComponent({
  name: "App",
  components: {
    Menubar,
  },
  data() {
    return {
      items: [] as MenuItem[],
    };
  },
  beforeMount() {
    this.items = [
      {
        label: "Page d'accueil",
        icon: "fas fa-home",
        url: "/",
      },
      {
        label: "Validation",
        icon: "fas fa-file-waveform",
        url: "/validation",
      },
      {
        label: "À propos",
        icon: "fas fa-info-circle",
        url: "/about",
      },
      {
        label: "Documentation",
        icon: "fas fa-book",
        url: "/documentation",
      },
    ];
  },
});
</script>

<style>
.container {
  margin-top: 2rem;
  max-width: 90%;
  margin-left: auto;
  max-height: calc(100vh - 150px);
}

* {
  font-weight: normal;
}
</style>
