<template>
  <div v-if="errorList.length === 0" style="display: flex; align-items: center">
    <i class="fas fa-check" style="color: green"></i>
    <p style="margin-left: 10px">Aucune erreur n'a été trouvée</p>
  </div>
  <div
    v-for="(errorInfo, index) in errorList"
    :key="errorInfo.key"
    class="error-item"
  >
    <div class="error-header">
      <div>
        <span class="error-icon" @click="toggleDetails(errorInfo)">
          <i class="fa-solid fa-triangle-exclamation red"></i>
        </span>
        <span class="field-name">Page: {{ errorInfo.page }} -</span>
        <span class="error-code">{{ errorInfo.code }}:</span>
        <span class="field-name">{{ errorInfo.fieldName }}</span>
      </div>
      <span class="chevron-icon">
        <i
          :class="
            !errorList[index].showDetails
              ? 'fas fa-chevron-down'
              : 'fas fa-chevron-up'
          "
          v-on:click="
            () => (errorList[index].showDetails = !errorList[index].showDetails)
          "
        ></i>
      </span>
    </div>
    <div v-if="errorInfo.showDetails">
      <p v-html="errorInfo.message"></p>
      <p v-if="errorInfo.info">{{ errorInfo.info }}</p>
    </div>
  </div>
  <br />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IErrorInfo } from "@/Models/ErrorInfo";

export default defineComponent({
  name: "ErrorSummary",
  props: {
    errorListProps: {
      type: Array as () => IErrorInfo[],
      required: true
    }
  },
  data() {
    return {
      errorList: [] as IErrorInfo[]
    };
  },
  methods: {
    toggleDetails(errorInfo: IErrorInfo) {
      errorInfo.showDetails = !errorInfo.showDetails;
    }
  },
  beforeMount() {
    this.errorList = this.errorListProps;
  }
});
</script>
<style scoped>
.error-list {
  max-width: 600px;
}

.error-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 10px;
  max-width: 800px;
}

.error-header {
  display: flex;
  justify-content: space-between;
}

.error-icon {
  margin-right: 10px;
  cursor: pointer;
}

.error-code {
  font-weight: bold;
  margin-right: 10px;
}

.field-name {
  margin-right: 10px;
}

.chevron-icon {
  cursor: pointer;
}

.error-details {
  margin-top: 10px;
}
.red {
  color: #b00020;
}
</style>
