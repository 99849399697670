<template>
  <h2>Validation du document</h2>
  <ProgressBar
    v-if="isLoading"
    mode="indeterminate"
    style="height: 6px; max-width: 70%"
  ></ProgressBar>
  <Toast />
  <p v-if="error">{{ error }}</p>
  <div v-if="!isLoading">
    <Button
      v-if="responseData"
      icon="fas fa-copy"
      aria-label="Filter"
      label="Copier le projet de courriel"
      v-on:click="copyText"
    />
    <Button
      style="margin-left: 10px"
      icon="fas fa-download"
      aria-label="Filter"
      label="Télécharger le document annoté"
      v-on:click="drawOnDocument"
      severity="secondary"
    />
    <hr />
    <ErrorSummary v-if="!error" :errorListProps="errorList" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ErrorSummary from "@/components/ErrorSummary.vue";

import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import Toast from "primevue/toast";

import { IErrorInfo } from "@/Models/ErrorInfo";
import axios from "axios";
import {
  IComment,
  IFieldData,
  ILayoutResponse,
  IPageData,
} from "@/Models/ExtractionResult";

export const EMAIL_TEMPLATE = `
Veuillez inscrire toute autre information jugée pertinente pour l’équipe de souscription (p.ex. : Détailler les antécédents judiciaires, préciser le nombre de prélèvements bancaires souhaité par votre client, etc.) \n
`;

const API_URL = "https://ai.cemiar.dev/api/ai-solutions/layout/scenario/jette";

function createEmail(error: string[], comments: string[]): string {
  let emailText = EMAIL_TEMPLATE;
  if (error.length > 0) {
    emailText += "\n\nListe des erreurs:\n";
    error.forEach((e, i) => {
      emailText += `${i + 1}. ${e}\n`;
    });

    if (comments.length > 0) {
      emailText += "\n\nRemarques:\n";
      comments.forEach((c, i) => {
        emailText += `${i + 1}. ${c}\n`;
      });
    }
  }
  return emailText;
}

export default defineComponent({
  name: "DocumentValidation",
  components: {
    ErrorSummary,
    ProgressBar,
    Button,
    Toast,
  },
  data() {
    return {
      errorList: [] as IErrorInfo[],
      responseData: {} as ILayoutResponse | undefined,
      error: "",
      isLoading: true,
      errorTextTemplate: "",
      fileBase64: "",
      fileName: "",
    };
  },
  methods: {
    copyText() {
      const errorFieldsName = this.errorList.map(
        (error: IErrorInfo) => `${error.fieldName}\n${error.message}\n`
      );
      const comments: string[] = [];
      this.responseData?.pagesData.forEach((page: IPageData) => {
        return page.comments.forEach((comment: IComment) => {
          comments.push(comment.value);
        });
      });
      const email = createEmail(errorFieldsName, comments);
      navigator.clipboard.writeText(email);
    },
    validateFileMissingFields(response: ILayoutResponse) {
      this.errorList = [];
      if (!response || response.pagesData.length === 0) {
        return;
      }

      response.pagesData.forEach((pageData: IPageData, index: number) => {
        pageData.fields.forEach((field: IFieldData) => {
          if (field.isValid === false) {
            this.errorList.push({
              fieldName: field.name,
              message: field.error,
              key: field.key,
              code: "CHAMP_OBLIGATOIRE",
              page: field.page,
              section: field.section,
              info: field.info,
            });
          }
        });
      });
    },
    toggleDetails(error: IErrorInfo) {
      error.showDetails = !error.showDetails;
    },
    async drawOnDocument() {
      this.isLoading = true;
      try {
        const response = await axios.post(
          API_URL + "/draw",
          {
            base64: this.fileBase64,
            config: [],
          },
          { responseType: "blob" }
        );
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.download = this.fileName.split(".")[0] + ".zip"; // Specify the filename

        // Append the link to the document body and click it to trigger download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
      } catch (error: any) {
        console.error("Error making HTTP POST request:", error);
        this.error = error.response
          ? error.response.data["error"]
          : error.message;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchFieldsFromFile(fileBase64: string): Promise<void> {
      if (!fileBase64) {
        this.responseData = undefined;
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      try {
        const response = await axios.post<ILayoutResponse>(API_URL, {
          file: fileBase64,
        });

        this.responseData = response.data;
        this.validateFileMissingFields(response.data);
      } catch (error: any) {
        console.error("Error making HTTP POST request:", error);
        this.error = error.response
          ? error.response.data["error"]
          : error.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  beforeMount() {
    this.responseData = undefined;
    const base64String = this.$store.getters.getBase64String;
    if (!base64String) {
      this.$router.push({ name: "validation" });
      return;
    }
    this.fileBase64 = base64String;
    this.fileName = this.$store.getters.getFileName;
    this.fetchFieldsFromFile(base64String);
  },
});
</script>
<style scoped>
.content {
  max-height: calc(100vh - 250px);
}
</style>
