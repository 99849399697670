<!-- FileUpload.vue -->
<template>
  <div class="file-upload">
    <h2>Validation de document</h2>
    <p>
      Glissez-déposez votre document PDF ici ou cliquez pour le sélectionner.
    </p>
    <div
      class="drop-area"
      @dragover.prevent="handleDragOver"
      @drop.prevent="handleDrop"
      @dragleave="handleDragLeave"
      :class="{ highlight: isDragging, 'highlight-red': isInvalidFileType }"
      :style="{ 'pointer-events': isInvalidFileType ? 'none' : 'auto' }"
    >
      <img
        src="https://cdn.icon-icons.com/icons2/3252/PNG/512/cloud_arrow_up_regular_icon_204518.png"
      />
      <div v-if="!preview?.name">
        <Button
          class="choose-file-button"
          label="Cliquez Ici"
          text
          v-on:click="triggerFileInput"
        />
        <br />
        <span>ou glissez-déposez votre fichier ici</span>
      </div>
      <div v-if="preview" class="preview">
        <p>
          {{ preview.name }}
          <i class="fas fa-trash-can" v-on:click="removeFile"></i>
        </p>
      </div>
    </div>
    <br />
    <Button
      @click="submitDocument"
      :disabled="preview?.name == null"
      class="submit-button"
      label="Valider"
    />
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      accept=".pdf"
      @change="handleFileChange"
    />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from "vue";
import Button from "primevue/button";

export default defineComponent({
  name: "FileUploader",
  components: {
    Button
  },
  data() {
    return {
      preview: null as null | { url: string; name: string },
      isDragging: false,
      isInvalidFileType: false
    };
  },
  methods: {
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        this.displayFilePreview(input.files[0]);
      }
    },
    handleDragOver(event: DragEvent) {
      event.preventDefault();
      event.dataTransfer!.dropEffect = "copy";
      this.isDragging = true;
      this.isInvalidFileType = false; // Reset the flag on dragover
    },
    handleDragLeave(event: DragEvent) {
      event.preventDefault();
      this.isDragging = false;
      this.isInvalidFileType = false; // Reset the flag on drag leave
    },
    handleDrop(event: DragEvent) {
      event.preventDefault();
      this.isDragging = false;
      const file = event.dataTransfer!.files[0];
      if (file) {
        if (file.type !== "application/pdf") {
          // File type is not PDF
          this.isInvalidFileType = true;
          return;
        }
        this.displayFilePreview(file);
      }
    },
    displayFilePreview(file: File) {
      const reader = new FileReader();
      reader.onload = () => {
        this.preview = {
          url: reader.result as string,
          name: file.name
        };
      };
      reader.readAsDataURL(file);
      this.isInvalidFileType = false; // Reset the flag after displaying a valid file
    },
    triggerFileInput() {
      this.isInvalidFileType = false; // Reset the flag on triggering file input
      (this.$refs.fileInput as HTMLInputElement).click();
    },
    removeFile() {
      this.preview = null;
      this.isInvalidFileType = false; // Reset the flag on file removal
    },
    async submitDocument() {
      if (!this.preview || !this.preview.url) {
        return;
      }
      const base64String = this.preview.url.split(",")[1];
      this.$store.dispatch("setBase64String", base64String);
      this.$store.dispatch("setFileName", this.preview.name);
      this.$router.push({ name: "analysis" });
    }
  }
});
</script>

<style scoped>
img {
  width: 300px;
  height: 300px;
}

i {
  cursor: pointer;
}
.submit-button {
  display: inline-block;
  margin-top: 20px;
}

.choose-file-button {
  padding: 0px;
}

span {
  vertical-align: middle;
}

.file-upload {
  text-align: center;
  max-width: 100%;
}

p {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.drop-area {
  /* max-width: 60%; */
  width: 600px;
  margin-top: 20px;
  border: 2px dashed #ccc;
  padding: 20px;
  display: inline-block;
  height: 500px;
  border-radius: 10px;
}

.drop-area.highlight {
  background-color: #87dcc0; /* Light green */
}

.drop-area.highlight-red {
  background-color: #ffaaaa; /* Light red */
}

.preview {
  margin-top: 20px;
}

/* Style for the remove button */
.preview button {
  margin-top: 10px;
}
</style>
