<template>
  <p>Bienvenue à la page</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePage",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
});
</script>
