// store.ts
import { InjectionKey } from "vue";
import { Store, createStore, useStore as baseUseStore } from "vuex";

interface State {
  base64String: string;
  fileName: string;
}

export const store = createStore<State>({
  state: {
    base64String: "",
    fileName: ""
  },
  mutations: {
    setBase64String(state, payload) {
      state.base64String = payload;
    },
    setFileName(state, payload) {
      state.fileName = payload;
    }
  },
  actions: {
    setBase64String({ commit }, payload) {
      commit("setBase64String", payload);
    },
    setFileName({ commit }, payload) {
      commit("setFileName", payload);
    }
  },
  getters: {
    getBase64String: state => state.base64String,
    getFileName: state => state.fileName
  }
});
